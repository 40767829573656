import baseComponent from '@/scripts/baseComponent'
import { mapState } from 'vuex'
import { API_CALL_STATUS } from '@/constant/config'
import draggable from 'vuedraggable'
import lazyLoadComponent from '@/scripts/lazyLoadComponent'
import SkeletonBox from '@/components/SkeletonBox'
import { bannerTypes, bannerSizes } from '@/constant/bannerOptions'
import bannerApi from '@/api/common/bannerApi'
export default {
    extends: baseComponent,
    components: {
        draggable,
        BannerEdit: lazyLoadComponent({
            componentFactory: () => import('@/views/adminManager/banner/BannerEdit.vue'),
            loading: SkeletonBox
        })
    },
    data() {
        return {
            bannerTypes,
            bannerSizes,
            listData: [],
            isLoading: false,
            pagination: {
                status: null,
                querySearch: '',
                platformView: 3,
                bannerType: bannerTypes[0],
                bannerSize: bannerSizes[0],
                orderBy: 'Ordered',
                directionSort: 'ASC',
                pageIndex: 1,
                pageSize: 100,
                outRowsNumber: 0
            },
            toogleFilterMobile: false,
            drag: false,
            editItem: null,
            isModified: false
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            }
        },
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            listStaff: (state) => state.staffs,
            decentralization: (state) => state.auth.decentralization
        })
    },
    watch: {
        toogleFilterMobile(val) {
            if (val) $('body').addClass('has-filter-enable')
            else $('body').removeClass('has-filter-enable')
        }
    },

    mounted() {
        window.addEventListener('resize', this.onResize)
        this.searchList()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        toogleFilterPanelMobile() {
            this.toogleFilterMobile = !this.toogleFilterMobile
        },
        onResize() {
            this.toogleFilterMobile = false
        },
        dragEnded() {
            this.isLoading = true
            this.drag = false
            const updateOrderRequest = this.listData
                .map((xItem, xItemIndex) => {
                    return { id: xItem.id, ordered: xItemIndex }
                })
                .sort((a, b) => a.ordered - b.ordered)
            //console.log('updateOrderRequest', updateOrderRequest)
            bannerApi
                .orderedBanner({
                    items: updateOrderRequest
                })
                .then((res) => {
                    this.searchList()
                    this.isLoading = false
                })
                .catch((error) => {
                    console.log('orderedBanner -> error', error)
                    this.searchList()
                    this.isLoading = false
                })
        },
        searchList(resetFilter = false) {
            this.$set(this, 'listData', [])
            this.isLoading = true
            bannerApi
                .getBannersMngtView({
                    status: this.pagination.status,
                    querySearch: this.pagination.querySearch,
                    platform: this.pagination.platformView,
                    bannerType: this.pagination.bannerType,
                    bannerSize: this.pagination.bannerSize,
                    orderBy: this.pagination.orderBy,
                    directionSort: this.pagination.directionSort,
                    pageIndex: this.pagination.pageIndex,
                    pageSize: this.pagination.pageSize
                })
                .then((res) => {
                    try {
                        if (res.data.result === 0) {
                            this.$set(
                                this,
                                'listData',
                                res.data.data.items.map((x) => ({
                                    ...x,
                                    imageUrl: x.banner,
                                    description: x.details
                                }))
                            )
                            this.$set(this, 'pagination', {
                                status: this.pagination.status,
                                querySearch: this.pagination.querySearch,
                                platformView: this.pagination.platformView,
                                bannerType: this.pagination.bannerType,
                                bannerSize: this.pagination.bannerSize,
                                orderBy: res.data.data.pagingItem.orderBy,
                                directionSort: res.data.data.pagingItem.directionSort,
                                pageIndex: res.data.data.pagingItem.pageIndex,
                                pageSize: res.data.data.pagingItem.pageSize,
                                outRowsNumber: res.data.data.pagination.totalCount
                            })
                            this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData)
                        }
                    } catch (error) {
                        console.log('getBannersMngtView -> error', error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED)
                    }
                    this.isLoading = false
                })
                .catch((error) => {
                    console.log('getBannersMngtView -> error', error)
                    this.feedStatusLoading(API_CALL_STATUS.FAILED)
                    this.isLoading = false
                })
        },
        addHandle() {
            this.isModified = false
            this.editItem = {}
        },
        editHandle(item) {
            this.isModified = true
            this.editItem = { ...item }
        },
        copyHandle(item) {
            this.isModified = true
            this.editItem = { ...item, id: null, status: 0 }
        },
        deleteHandle(item) {
            this.$confirm('This will permanently delete the item. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            })
                .then(() => {
                    bannerApi
                        .deleteBanner(item.id)
                        .then((res) => {
                            try {
                                if (res.data.result === 0) {
                                    this.searchList()
                                    this.$message({
                                        type: 'success',
                                        message: 'Delete completed'
                                    })
                                }
                            } catch (error) {
                                console.log('deleteBanner -> error', error)
                            }
                            this.isLoading = false
                        })
                        .catch((error) => {
                            console.log('deleteBanner -> error', error)
                            this.isLoading = false
                        })
                })
                .catch(() => { })
        },
        dropdownItemAction(params) {
            switch (params.action) {
                case 'edit':
                    this.editHandle(params.item)
                    break
                case 'copy':
                    this.copyHandle(params.item)
                    break
                case 'delete':
                    this.deleteHandle(params.item)
                    break
            }
        }
    }
}
