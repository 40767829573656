export const bannerTypes = [
    "RECHARGE",
    "MACHINE",
    "SUPPORT",
    "ABOUT_US",
    "DOCUMENT",
    "EXTRA_SERVICE",
    "TRANSFER_DATA",
    "BILLING",
    "API",
    "LINK"
]

export const bannerPlatforms = {
    iOS: 1,
    Android: 2,
    WebApp: 3,
    DesktopApp: 4
}

export const bannerSizes = ['850x150', '1133x200', '1500x500', '1080x1080', '1200x150', '1280x720', '1584x396', '1702x630']