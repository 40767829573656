import axios from "axios";
export default {
    getBannersMngtView(request) {
        return axios({
            method: "POST",
            url: `/api/banner-mngt/get-banner-items`,
            data: request,
            withCredentials: true,
        });
    },
    saveBanner(request) {
        return axios({
            method: "POST",
            url: `/api/banner-mngt/save-banner`,
            data: request,
            withCredentials: true,
        });
    },
    deleteBanner(id) {
        return axios({
            method: "DELETE",
            url: `/api/banner-mngt/delete-banner/${id}`,
            withCredentials: true,
        });
    },
    orderedBanner(request) {
        return axios({
            method: "POST",
            url: `/api/banner-mngt/update-ordered-banner`,
            data: request,
            withCredentials: true,
        });
    },
};
